<template>
    <div class="fixed bottom-0 left-0 w-full">
        <div class="flex w-full">
            <div class="progress !rounded-none">
                <div
                    class="progress-bar !rounded-none"
                    :style="{ width: `${scroll_progress}%` }"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const scroll_progress = ref(0);

const calculate_scroll_progress = () => {
    const scroll_top = window.scrollY;
    const scroll_height =
        document.documentElement.scrollHeight - window.innerHeight;
    scroll_progress.value = Math.min((scroll_top / scroll_height) * 100, 100);

    // Track it
    if (scroll_progress.value > 90) {
        let articles_read = localStorage.getItem("articles_read") || "[]";
        articles_read = JSON.parse(articles_read);

        if (!articles_read.includes(window.location.pathname)) {
            articles_read.push(window.location.pathname);
            localStorage.setItem(
                "articles_read",
                JSON.stringify(articles_read),
            );

            try {
                fbq("track", "ViewContent", {
                    content_name: document.title,
                });
            } catch (error) {}
        }
    }
};

onMounted(() => {
    window.addEventListener("scroll", calculate_scroll_progress);
});

onUnmounted(() => {
    window.removeEventListener("scroll", calculate_scroll_progress);
});
</script>
