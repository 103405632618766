// Wrappers
import StoreWrapper from "./helpers/wrappers/store";

// Services
import CommonService from "@/services/common";

export default {
    async contact({ $form, append = {} }) {
        return StoreWrapper(new CommonService().contact, {
            $form,
            append,
        });
    },

    async feedback({ $form, append = {} }) {
        return StoreWrapper(new CommonService().feedback, {
            $form,
            append,
        });
    },

    async track({ append = {} }) {
        return StoreWrapper(new CommonService().track, {
            $form: null,
            append,
            silent: true,
        });
    },
};
