<template>
    <div class="floating-banner" v-if="!hidden">
        <div class="container !max-w-screen-sm">
            <div class="content-wrapper">
                <span class="button-close" @click="toggle_hidden()">
                    <i class="fi fi-rr-cross"></i>
                </span>

                <title_component :light="true" @click="toggle_expanded()">
                    <template #title>
                        <template v-if="!expanded">
                            <i class="fi fi-rr-plan-strategy mr-1"></i>
                            {{ gt("Get a Plan to Overcome Anxiety") }}
                        </template>

                        <template v-if="expanded">
                            {{ gt("A Plan Designed for You") }}
                        </template>
                    </template>

                    <template #subtitle v-if="!expanded">
                        {{ gt("Clicking here") }}
                        <emoji_component
                            emoji="hand"
                            class="w-[21px] h-[21px] ml-2"
                        />
                    </template>
                </title_component>

                <template v-if="expanded">
                    <p class="description">
                        {{ gt("Tackle symptoms such as:") }}
                    </p>

                    <ul>
                        <li class="text-slate-50">
                            <i class="fi fi-rr-caret-right"></i>
                            {{ gt("Overthinking") }}
                        </li>

                        <li class="text-slate-50">
                            <i class="fi fi-rr-caret-right"></i>
                            {{ gt("Negative thoughts") }}
                        </li>

                        <li class="text-slate-50">
                            <i class="fi fi-rr-caret-right"></i>
                            {{ gt("Insomnia") }}
                        </li>
                    </ul>

                    <p class="description">
                        {{ gt("Get support and guidance with:") }}
                    </p>

                    <ul>
                        <li class="text-slate-50">
                            <i class="fi fi-rr-check"></i>
                            {{ gt("Content by certified psychologists") }}
                        </li>

                        <li class="text-slate-50">
                            <i class="fi fi-rr-check"></i>
                            {{ gt("Professional psychological guidance") }}
                        </li>
                    </ul>

                    <div class="flex flex-col gap-2">
                        <link_component
                            :classes="['btn', 'btn-light', 'w-full']"
                            :path="'/register'"
                        >
                            <i class="fi fi-rr-plan-strategy"></i>
                            {{ gt("Start today with your email") }}
                        </link_component>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
// Components
import { defineAsyncComponent } from "vue";

const title_component = defineAsyncComponent(
    () => import("@/components/commons/title.vue"),
);

const link_component = defineAsyncComponent(
    () => import("@/components/commons/link.vue"),
);

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Libs
import { ref } from "vue";

// Variables
const expanded = ref(false);
const hidden = ref(false);

const toggle_expanded = () => {
    expanded.value = !expanded.value;
};

const toggle_hidden = () => {
    hidden.value = !hidden.value;
};
</script>
<style lang="scss" scoped>
@use "./styles/index.scss";
</style>
