// Wrappers
import GetWrapper from "./helpers/wrappers/get.js";
import DeleteWrapper from "./helpers/wrappers/delete.js";

// Services
import SubscriptionService from "@/services/subscription.js";
import UnitService from "@/services/unit.js";

export default {
    async get(args = {}) {
        const { success, subscriptions, pagination } = await GetWrapper(
            new SubscriptionService().get,
            args,
        );

        return {
            success,
            records: subscriptions || [],
            pagination,
        };
    },

    async get_payment_link(args = {}) {
        const { success, link } = await GetWrapper(
            new SubscriptionService().get_payment_link,
            args,
        );

        return {
            success,
            link,
        };
    },

    async get_subscriptions(args = {}) {
        const { success, subscriptions } = await GetWrapper(
            new SubscriptionService().get_subscriptions,
            args,
        );

        return {
            success,
            subscriptions,
        };
    },

    async cancel_all_subscriptions() {
        const { success, message } = await DeleteWrapper(
            new SubscriptionService().cancel_all_subscriptions,
            {
                silent: true,
            },
        );

        return {
            success,
            message,
        };
    },
};
