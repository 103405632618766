// Wrappers
import GetWrapper from "./helpers/wrappers/get";

// Services
import RankingService from "@/services/ranking.js";

export default {
    async get(args = {}) {
        const { success, ranking } = await GetWrapper(
            new RankingService().get,
            args,
        );

        return {
            success,
            records: ranking || [],
        };
    },
};
