// Stores
import AppStore from "@/stores/app";
import UserStore from "@/stores/user";

// Helpers
import get_locale from "@/helpers/get_locale.js";

export default function () {
    const App = AppStore();

    window.Echo.channel("public.notifications").listen(
        ".notification",
        (data) => {
            const {
                locale,
                level,
                type,
                close_after,
                title,
                description,
                content,
            } = data;

            if (locale !== get_locale()) {
                return;
            }

            App.notification({
                title,
                message: description,
                level,
                type,
                close_after,
            });
        },
    );
}
