<template>
    <mobile_version v-if="is_mobile" />
    <desktop_version v-else />
</template>

<script setup>
// Libs
import {
    defineAsyncComponent,
    ref,
    onMounted,
    onUnmounted,
    onBeforeMount,
} from "vue";
import { useRouter } from "vue-router";

// Components
const mobile_version = defineAsyncComponent(() => import("./mobile.vue"));
const desktop_version = defineAsyncComponent(() => import("./desktop.vue"));

// Helpers
import is_native_framework from "@/helpers/is_native_framework.js";

// Stores
import UserStore from "@/stores/user";

// Variables
const User = UserStore();
const { is_logged } = User;
const $router = is_native_framework() ? useRouter() : null;
const is_mobile = ref(false);
let mediaQuery;

// Methods
function redirect_if_is_not_logged() {
    if (is_logged) return;
    if (!$router) {
        window.location.href = "/login";
    } else {
        $router.push("/login");
    }
}

function handleMediaQueryChange(event) {
    is_mobile.value = !event.matches;
}

// Lifecycle
onBeforeMount(() => {
    redirect_if_is_not_logged();
});

onMounted(() => {
    if (!is_logged) return;
    mediaQuery = window.matchMedia("(min-width: 768px)");
    is_mobile.value = !mediaQuery.matches;
    mediaQuery.addEventListener("change", handleMediaQueryChange);
});

onUnmounted(() => {
    if (mediaQuery) {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
    }
});
</script>
