// Wrappers
import StoreWrapper from "./helpers/wrappers/store";
import GetWrapper from "./helpers/wrappers/get";
import DeleteWrapper from "./helpers/wrappers/delete";

// Services
import SymptomService from "@/services/symptom";

export default {
    async get(args = {}) {
        const { success, symptoms, pagination } = await GetWrapper(
            new SymptomService().get,
            args,
        );

        return {
            success,
            records: symptoms || [],
            pagination,
        };
    },

    async store({ $form, append = {} }) {
        return StoreWrapper(new SymptomService().store, {
            $form,
            append,
        });
    },

    async delete({ id }) {
        const { success, message } = await DeleteWrapper(
            new SymptomService().delete,
            { id },
        );

        return {
            success,
            message,
        };
    },
};
