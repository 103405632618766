<template>
    <div
        v-if="post?.stats"
        :class="['badge', liked === true ? 'badge-dark' : 'badge-light']"
        @click="like()"
    >
        <i class="fi fi-rr-social-network"></i>
        {{ post.stats.total_likes }}
    </div>
</template>

<script setup>
// Components
import { defineAsyncComponent } from "vue";

const loader_component = defineAsyncComponent(
    () => import("@/components/commons/loader.vue"),
);

// Libs
import { toRefs, ref, onMounted } from "vue";
import Post from "@/methods/for_posts";

// Props
const props = defineProps({
    post_id: {
        default: null,
    },
});

// Define props
const post = ref({});
const liked = ref(false);
const { post_id } = toRefs(props);

// Methods
const get_stats = async () => {
    const response = await Post.get_stats({
        post_id: post_id.value,
    });

    post.value = response.post;
    liked.value = response?.liked_by_me;
};

const like = async () => {
    liked.value = !liked.value;
    await Post.update_post_like({ post_id: post_id.value });
    await get_stats();
};

// Lifecycle
onMounted(() => {
    get_stats();
});
</script>
