import BaseService from "@/services/base";

export default class extends BaseService {
    constructor(args = {}) {
        super(args);
    }

    get = async (body = {}) => {
        return await this.execute({
            name: "plans.get",

            method: "get",
            target: "/v1/plans",
            body,

            schema: {
                success: {
                    required: ["plans", "pagination"],
                    properties: {
                        plans: { type: "array" },
                        pagination: { type: "object" },
                    },
                },
            },
        });
    };

    my_plans = async (body = {}) => {
        return await this.execute({
            name: "my.plans",

            method: "get",
            target: "/v1/my/plans",
            body,

            schema: {
                success: {
                    required: ["plans"],
                    properties: {
                        plans: { type: "array" },
                    },
                },
            },
        });
    };

    my_plan_status = async (body = {}) => {
        return await this.execute({
            name: "my.plan_status",

            method: "get",
            target: "/v1/my/plan/status",
            body,

            schema: {
                success: {
                    required: ["status"],
                    properties: {
                        status: { type: "object" },
                    },
                },
            },
        });
    };

    store = async (body = {}) => {
        return await this.execute({
            name: "plan.store",

            method: "post",
            target: "/v1/plan",
            body,
        });
    };

    delete = async (body = {}) => {
        return await this.execute({
            name: "plan.delete",

            method: "delete",
            target: "/v1/plan",
            body,
        });
    };

    assign = async (body = {}) => {
        return await this.execute({
            name: "plan.assign",

            method: "post",
            target: "/v1/plan/assign",
            body,
        });
    };

    unassign = async (body = {}) => {
        return await this.execute({
            name: "plan.unassign",

            method: "delete",
            target: "/v1/plan/assign",
            body,
        });
    };

    update_setting = async (body = {}) => {
        return await this.execute({
            name: "plan.update_setting",

            method: "post",
            target: "/v1/plan/setting",
            body,
        });
    };

    add_route = async (body = {}) => {
        return await this.execute({
            name: "plan.add_route",

            method: "post",
            target: "/v1/plan/route",
            body,
        });
    };

    remove_route = async (body = {}) => {
        return await this.execute({
            name: "plan.remove_route",

            method: "delete",
            target: "/v1/plan/route",
            body,
        });
    };
}
