// Service
import BaseService from "@/services/base";

export default class extends BaseService {
    constructor(args = {}) {
        super(args);
    }

    contact = async (body = {}) => {
        return await this.execute({
            name: "common.contact",

            method: "post",
            target: "/v1/contact",
            body,
        });
    };

    feedback = async (body = {}) => {
        return await this.execute({
            name: "common.feedback",

            method: "post",
            target: "/v1/feedback",
            body,
        });
    };

    track = async (body = {}) => {
        return await this.execute({
            name: "common.track",

            method: "post",
            target: "/v1/track",
            body,
        });
    };
}
