import BaseService from "@/services/base";

export default class extends BaseService {
    constructor(args = {}) {
        super(args);
    }

    get = async (body = {}) => {
        return await this.execute({
            name: "posts.get",

            method: "get",
            target: "/v1/posts",
            body,

            schema: {
                success: {
                    required: ["posts", "pagination"],
                    properties: {
                        posts: { type: "array" },
                        pagination: { type: "object" },
                    },
                },
            },
        });
    };

    store = async (body = {}) => {
        return await this.execute({
            name: "post.store",

            method: "post",
            target: "/v1/post",
            body,

            schema: {
                success: {
                    required: ["post"],
                    properties: {
                        post: { type: "object" },
                    },
                },
            },
        });
    };

    delete = async (body = {}) => {
        return await this.execute({
            name: "post.delete",

            method: "delete",
            target: "/v1/post",
            body,
        });
    };

    update_like = async (body = {}) => {
        return await this.execute({
            name: "post.update_like",

            method: "post",
            target: "/v1/post/update/like",
            body,
        });
    };

    get_stats = async (body = {}) => {
        return await this.execute({
            name: "post.get_stats",

            method: "get",
            target: "/v1/post/stats",
            body,

            schema: {
                success: {
                    required: ["post", "liked_by_me"],
                    properties: {
                        post: { type: "object" },
                        liked_by_me: { type: "boolean" },
                    },
                },
            },
        });
    };

    get_correlations = async (body = {}) => {
        return await this.execute({
            name: "post.get_correlations",

            method: "get",
            target: "/v1/post/correlations",
            body,

            schema: {
                success: {
                    required: ["correlations"],
                    properties: {
                        correlations: { type: "array" },
                    },
                },
            },
        });
    };

    update_image_using_unsplash = async (body = {}) => {
        return await this.execute({
            name: "post.update_image_using_unsplash",

            method: "post",
            target: "/v1/post/update_image_using_unsplash",
            body,
        });
    };

    store_correlation = async (body = {}) => {
        return await this.execute({
            name: "post.store_correlation",

            method: "post",
            target: "/v1/post/correlation",
            body,
        });
    };

    delete_correlation = async (body = {}) => {
        return await this.execute({
            name: "post.delete_correlation",

            method: "delete",
            target: "/v1/post/correlation",
            body,
        });
    };

    get_ai_content = async (body = {}) => {
        return await this.execute({
            name: "post.get_ai_content",

            method: "get",
            target: `/v1/ai/post/${body.target}`,
            body,
        });
    };

    categories = async (body = {}) => {
        return await this.execute({
            name: "post.categories",

            method: "get",
            target: "/v1/post/categories",
            body,

            schema: {
                success: {
                    required: ["categories", "pagination"],
                    properties: {
                        categories: { type: "array" },
                        pagination: { type: "object" },
                    },
                },
            },
        });
    };

    delete_category = async (body = {}) => {
        return await this.execute({
            name: "post.category.delete",

            method: "delete",
            target: "/v1/post/category",
            body,
        });
    };

    update_category = async (body = {}) => {
        return await this.execute({
            name: "post.category.store",

            method: "post",
            target: "/v1/post/category",
            body,
        });
    };
}
