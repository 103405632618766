<template>
    <div class="container !max-w-[480px] mt-[100px]">
        <div class="flex flex-col gap-4">
            <form_recover_component
                :on_response="on_response"
            ></form_recover_component>
        </div>
    </div>
</template>
<script setup>
// Libs
import { defineAsyncComponent, onMounted } from "vue";
import { useRouter } from "vue-router";

// Components
const form_recover_component = defineAsyncComponent(
    () => import("@/components/commons/forms/recover.vue"),
);

// Stores
import UserStore from "@/stores/user";

// Helpers
import url from "@/helpers/url";
import is_native_framework from "@/helpers/is_native_framework.js";

// Variables
const User = UserStore();

const { is_logged } = User;
const $router = is_native_framework() ? useRouter() : null;

// Methods
const on_response = ({ success, user }) => {
    if (success) {
        const go_to = url({
            with_origin: false,
            path: "/login",
            params: {
                user_id: user.id,
                authorization: user.temporary_token,
            },
        });

        if ($router) {
            $router.push(go_to);
        } else {
            window.location.href = go_to;
        }
    }
};

// Lifecycle
onMounted(() => {
    if (is_logged) {
        if ($router) {
            $router.push("/journey");
        } else {
            window.location.href = "/journey";
        }
    }
});
</script>
