// Libs
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("top_navigation", () => {
    const visible = ref(true);
    const disabled = ref(false);
    const title = ref(null);
    const go_back = ref(null);
    const loading = ref(false);
    const background = ref("light");
    const opacity = ref(0.9);
    const updated_at = ref(new Date());

    const update = function (args = {}) {
        args.updated_at = new Date();

        Object.keys(args).forEach((key) => {
            this[key] = args[key];
        });
    };

    return {
        visible,
        disabled,
        title,
        background,
        opacity,
        go_back,
        loading,
        updated_at,

        update,
    };
});
