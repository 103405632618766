import BaseService from "@/services/base";

export default class extends BaseService {
    constructor(args = {}) {
        super(args);
    }

    authenticate = async (body = {}) => {
        return await this.execute({
            name: "user.authenticate",

            method: "post",
            target: "/v1/user/authenticate",
            body,

            schema: {
                success: {
                    required: ["user"],
                    properties: {
                        user: { type: "object" },
                    },
                },
            },
        });
    };

    get_my_info = async (body = {}) => {
        return await this.execute({
            name: "user.get_my_info",

            method: "get",
            target: "/v1/user",
            body,

            schema: {
                success: {
                    required: ["user"],
                    properties: {
                        user: { type: "object" },
                    },
                },
            },
        });
    };

    get = async (body = {}) => {
        return await this.execute({
            name: "users.get",

            method: "get",
            target: "/v1/users",
            body,

            schema: {
                success: {
                    required: ["users", "pagination"],
                    properties: {
                        users: { type: "array" },
                        pagination: { type: "object" },
                    },
                },
            },
        });
    };

    get_psychologists = async (body = {}) => {
        return await this.execute({
            name: "users.get_psychologists",

            method: "get",
            target: "/v1/users/psychologists",
            body,

            schema: {
                success: {
                    required: ["users", "pagination"],
                    properties: {
                        users: { type: "array" },
                        pagination: { type: "object" },
                    },
                },
            },
        });
    };

    store = async (body = {}) => {
        return await this.execute({
            name: "user.store",

            method: "post",
            target: "/v1/user",
            body,

            schema: {
                success: {
                    required: ["user"],
                    properties: {
                        user: { type: "object" },
                    },
                },
            },
        });
    };

    update = async (body = {}) => {
        return await this.execute({
            name: "user.update",

            method: "post",
            target: "/v1/user/update",
            body,
        });
    };

    recover_password = async (body = {}) => {
        return await this.execute({
            name: "user.recover_password",

            method: "post",
            target: "/v1/user/recover/password",
            body,
        });
    };

    refund = async (body = {}) => {
        return await this.execute({
            name: "user.refund",

            method: "post",
            target: "/v1/user/refund",
            body,
        });
    };

    delete = async (body = {}) => {
        return await this.execute({
            name: "user.delete",

            method: "delete",
            target: `/v1/user`,
            body,
        });
    };

    remove_notifications = async (body = {}) => {
        return await this.execute({
            name: "user.remove_notifications",

            method: "delete",
            target: `/v1/user/notifications/delete`,
            body,
        });
    };

    get_permissions = async (body = {}) => {
        return await this.execute({
            name: "user.get_permissions",

            method: "get",
            target: "/v1/user/permissions",
            body,

            schema: {
                success: {
                    required: ["permissions"],
                    properties: {
                        permissions: { type: "object" },
                    },
                },
            },
        });
    };
}
