// ENV Variables
import ENV from "@/../env.json";

// Stores
import AppStore from "@/stores/app";

/**
 * Delete a resource.
 */
export default async function (Service, args = {}) {
    const body = {};
    const silent = args.silent || false;
    const append = args.append || null;

    body.id = args.id || null;
    body.route_id = args.route_id || null;
    body.unit_id = args.unit_id || null;
    body.property_id = args.property_id || null;
    body.file_id = args.file_id || null;
    body.plan_id = args.plan_id || null;

    if (append) {
        Object.keys(append).forEach((key) => {
            if (append[key] !== null) {
                body[key] = append[key];
            }
        });
    }

    if (!silent && !confirm("Sure?")) {
        return {
            success: false,
        };
    }

    const response = await Service(body);

    const { success, message } = response;

    if (!silent && message) {
        try {
            AppStore().notification({
                message,
                level: success
                    ? ENV.enums.AlertLevels.SUCCESS
                    : ENV.enums.AlertLevels.DANGER,
            });
        } catch (error) {}
    }

    return response;
}
