<template>
    <div class="container mt-[100px] page-performance">
        <div class="flex flex-col gap-2">
            <h2 class="text-3xl leading-none">
                {{ gt("Hey") }}
            </h2>

            <title_component :size="'large'">
                <template #title>
                    {{ user_name }}
                    <emoji_component
                        emoji="hand"
                        class="inline w-[36px] aspect-square"
                    />
                </template>
                <template #subtitle>
                    {{ gt("Continue with your journey.") }}
                </template>
            </title_component>

            <top_component></top_component>

            <div class="flex gap-2">
                <link_component
                    :classes="['btn', 'btn-dark', 'w-fit']"
                    :path="'/journey'"
                >
                    <i class="fi fi-rr-play"></i>
                    {{ gt("Continue with your plan") }}
                </link_component>

                <link_component
                    :classes="['btn', 'btn-link', 'w-fit']"
                    :path="'/contact'"
                    :open_window="true"
                >
                    <i class="fi fi-rr-headphones"></i>
                    {{ gt("Help?") }}
                </link_component>
            </div>
        </div>
    </div>

    <routes_component />
</template>

<script setup>
// Components
import { defineAsyncComponent, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";

const title_component = defineAsyncComponent(
    () => import("@/components/commons/title.vue"),
);

const top_component = defineAsyncComponent(
    () => import("./components/top.vue"),
);

const link_component = defineAsyncComponent(
    () => import("@/components/commons/link.vue"),
);

const routes_component = defineAsyncComponent(
    () => import("./components/routes.vue"),
);

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Helpers
import get_words from "@/helpers/get_words";
import is_native_framework from "@/helpers/is_native_framework.js";

// Stores
import UserStore from "@/stores/user";
import TopNavigationStore from "@/stores/top_navigation";
import BottomNavigationStore from "@/stores/bottom_navigation";

// Variables
const User = UserStore();
const BottomNavigation = BottomNavigationStore();
const TopNavigation = TopNavigationStore();

const { user, is_logged } = User;
const $router = is_native_framework() ? useRouter() : null;

// Computed variables
const user_name = computed(() => {
    return get_words(user.name, 1, "");
});

// Methods
function redirect_if_is_not_logged() {
    if (is_logged) {
        return;
    }

    if (!$router) {
        window.location.href = "/login";
    } else {
        $router.push("/login");
    }
}

function set_title_and_navigation() {
    TopNavigation.update({
        loading: false,
        visible: true,
        title: null,
        go_back: null,
    });

    BottomNavigation.update({
        visible: true,
        active_menu: "home",
    });
}

// Lifecycle
onBeforeMount(() => {
    redirect_if_is_not_logged();
    set_title_and_navigation();
});
</script>
