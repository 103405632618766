<template>
    <div
        v-if="route?.stats"
        :class="['badge', liked === true ? 'badge-dark' : 'badge-light']"
        @click="like()"
    >
        <i class="fi fi-rr-social-network"></i>
        {{ route.stats.total_likes }}
    </div>
</template>

<script setup>
// Components
import { defineAsyncComponent } from "vue";

const loader_component = defineAsyncComponent(
    () => import("@/components/commons/loader.vue"),
);

// Libs
import { toRefs, ref, onMounted } from "vue";
import Route from "@/methods/for_routes";

// Props
const props = defineProps({
    route_id: {
        default: null,
    },
});

// Define props
const route = ref({});
const liked = ref(false);
const { route_id } = toRefs(props);

// Methods
const get_stats = async () => {
    const response = await Route.get_stats({
        route_id: route_id.value,
    });

    route.value = response.route;
    liked.value = response?.liked_by_me;
};

const like = async () => {
    liked.value = !liked.value;
    await Route.update_like({ route_id: route_id.value });
    await get_stats();
};

// Lifecycle
onMounted(() => {
    get_stats();
});
</script>
