// Libs
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("player", () => {
    // Vars
    const resource = ref(null);
    const ready = ref(false);
    const player = ref(null);
    const error = ref(null);
    const playing = ref(false);
    const progress = ref(0);
    const progress_in_seconds = ref("0:00");
    const total = ref("0:00");
    const remain = ref(0);
    const callbacks = {
        on_playing: ({ progress = 0 }) => {},
        on_finish: () => {
            playing.value = false;
        },
    };

    let forward_clicks = 0;
    let forward_timeout = null;

    // Methods
    const reset = () => {
        if (player.value) {
            player.value.pause();
            player.value.currentTime = 0;
            player.value.src = "";
            player.value = null;
        }

        ready.value = false;
        playing.value = false;
        progress.value = 0;
        remain.value = 0;
    };

    const init = ({ stream }) => {
        // Destroy the current player
        reset();

        if (!resource.value) {
            return;
        }

        player.value = new Audio(stream);
        player.value.addEventListener("timeupdate", () => {
            update_progress();
            callbacks.on_playing({ progress: progress.value });
        });
        player.value.addEventListener(
            "canplaythrough",
            () => (ready.value = true),
        );
        player.value.addEventListener("play", () => play());
        player.value.addEventListener("pause", () => pause());
        player.value.addEventListener("ended", () => {
            playing.value = false;
            callbacks.on_finish();
        });
    };

    const play = async () => {
        if (!player.value) {
            return;
        }

        try {
            await player.value.play();
            playing.value = true;
        } catch (error) {
            console.error("Error playing the audio:", error);
            error.value = error;
        }
    };

    const pause = async () => {
        if (!player.value) {
            return;
        }

        try {
            await player.value.pause();
            playing.value = false;
        } catch (error) {
            console.error("Error pausing the audio:", error);
            error.value = error;
        }
    };

    const toggle = async () => {
        if (!player.value) {
            return;
        }

        if (playing.value) {
            await pause();
        } else {
            await play();
        }
    };

    const go_to_second = (second) => {
        if (player.value && ready.value) {
            player.value.currentTime = second;
        }
    };

    const forward = (seconds = 3) => {
        if (player.value && ready.value) {
            forward_clicks += 1;
            const jump_seconds = seconds * (forward_clicks * 0.7);

            player.value.currentTime = Math.min(
                player.value.currentTime + jump_seconds,
                player.value.duration,
            );

            clearTimeout(forward_timeout);
            forward_timeout = setTimeout(() => {
                forward_clicks = 0;
            }, 1000);
        }
    };

    const rewind = (seconds = 1) => {
        if (player.value && ready.value) {
            player.value.currentTime = Math.max(
                player.value.currentTime - seconds,
                0,
            );
        }
    };

    const skip = () => {
        if (player.value && ready.value) {
            player.value.currentTime = player.value.duration - 3;
        }
    };

    const add_resource = (data) => {
        resource.value = data;
    };

    const update_callback = (callback_name, callback) => {
        Object.assign(callbacks, { [callback_name]: callback });
    };

    const update_progress = () => {
        if (player.value && player.value.duration) {
            remain.value = Math.round(
                player.value.duration - player.value.currentTime,
            );

            progress.value = Math.round(
                (player.value.currentTime / player.value.duration) * 100,
            );

            const current_minutes = Math.floor(player.value.currentTime / 60);
            const current_seconds = Math.floor(player.value.currentTime % 60);
            progress_in_seconds.value = `${current_minutes}:${current_seconds
                .toString()
                .padStart(2, "0")}`;

            const total_minutes = Math.floor(player.value.duration / 60);
            const total_seconds = Math.floor(player.value.duration % 60);
            total.value = `${total_minutes}:${total_seconds
                .toString()
                .padStart(2, "0")}`;
        }
    };

    return {
        resource,
        playing,
        progress,
        remain,
        ready,
        error,
        progress_in_seconds,
        total,

        init,
        reset,
        toggle,
        play,
        pause,
        forward,
        rewind,
        skip,
        go_to_second,

        update_callback,
        add_resource,
    };
});
