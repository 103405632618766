import { defineStore } from "pinia";
import { computed, ref, reactive } from "vue";

export default defineStore("user", () => {
    const user = ref(null);

    const is_logged = computed(() => {
        return (
            (user.value &&
                new Date(user.value.token_expires_at) > new Date()) ||
            (user.value && user.value.expired === true)
        );
    });

    function update(_) {
        const updated_user = { ...user.value, ..._ };
        localStorage.setItem("user", JSON.stringify(updated_user));
        user.value = updated_user;
    }

    function restore() {
        let stored_user = localStorage.getItem("user");

        if (!stored_user) {
            return;
        }

        try {
            stored_user = JSON.parse(stored_user);
            user.value = stored_user;
        } catch (e) {
            console.error("Failed to parse stored user", e);
        }
    }

    function logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("session_id");
        localStorage.removeItem("ignore_language_notification");
        localStorage.removeItem("locale");
        localStorage.removeItem("articles_read");

        user.value = null;
    }

    return {
        user,
        is_logged,
        update,
        restore,
        logout,
    };
});
