// Wrappers
import StoreWrapper from "./helpers/wrappers/store";
import GetWrapper from "./helpers/wrappers/get";
import DeleteWrapper from "./helpers/wrappers/delete";

// Services
import PlanService from "@/services/plan";

export default {
    async get(args = {}) {
        const { success, plans, pagination } = await GetWrapper(
            new PlanService().get,
            args,
        );

        return {
            success,
            records: plans || [],
            pagination,
        };
    },

    async my_plans(args = {}) {
        const { success, plans } = await GetWrapper(
            new PlanService().my_plans,
            args,
        );

        return {
            success,
            plans,
        };
    },

    async my_plan_status(args = {}) {
        const { success, status } = await GetWrapper(
            new PlanService().my_plan_status,
            args,
        );

        return {
            success,
            status,
        };
    },

    async store({ $form, append = {} }) {
        return StoreWrapper(new PlanService().store, {
            $form,
            append,
        });
    },

    async assign({ plan_id, user_id }) {
        return StoreWrapper(new PlanService().assign, {
            append: {
                plan_id,
                user_id,
            },
        });
    },

    async unassign({ plan_id, user_id }) {
        return DeleteWrapper(new PlanService().unassign, {
            plan_id,
            user_id,
        });
    },

    async assign_route({ plan_id, route_id }) {
        return StoreWrapper(new PlanService().add_route, {
            append: {
                plan_id,
                route_id,
            },
        });
    },

    async update_setting({ $form, append = {} }) {
        return StoreWrapper(new PlanService().update_setting, {
            $form,
            append,
        });
    },

    async unassign_route({ plan_id, route_id }) {
        return DeleteWrapper(new PlanService().remove_route, {
            plan_id,
            route_id,
        });
    },

    async delete({ id }) {
        return DeleteWrapper(new PlanService().delete, {
            id,
        });
    },
};
