// Services
import UserService from "@/services/user";

// Wrappers
import StoreWrapper from "./helpers/wrappers/store";
import GetWrapper from "./helpers/wrappers/get";
import DeleteWrapper from "./helpers/wrappers/delete";

export default {
    async get(args = {}) {
        const { success, users, pagination } = await GetWrapper(
            new UserService().get,
            args,
        );

        return {
            success,
            records: users || [],
            pagination,
        };
    },

    async get_psychologists(args = {}) {
        const { success, users, pagination } = await GetWrapper(
            new UserService().get_psychologists,
            args,
        );

        return {
            success,
            records: users || [],
            pagination,
        };
    },

    async store({ $form, append = {} }) {
        return StoreWrapper(new UserService().store, {
            $form,
            append,
        });
    },

    async update({ $form, append = {}, silent = false }) {
        return StoreWrapper(new UserService().update, {
            $form,
            append,
            silent,
        });
    },

    async refund() {
        return StoreWrapper(new UserService().refund, {});
    },

    async recover_password({ $form, append = {} }) {
        return StoreWrapper(new UserService().recover_password, {
            $form,
            append,
        });
    },

    async delete({ id, silent = false }) {
        const { success, message } = await DeleteWrapper(
            new UserService().delete,
            {
                id,
                silent,
            },
        );

        return {
            success,
            message,
        };
    },

    async remove_notifications({ id }) {
        const { success, message } = await DeleteWrapper(
            new UserService().remove_notifications,
            { id },
        );

        return {
            success,
            message,
        };
    },

    async get_permissions(args = {}) {
        const { permissions } = await GetWrapper(
            new UserService().get_permissions,
            args,
        );

        return {
            permissions,
        };
    },
};
