<template>
    <div class="flex flex-col mt-[100px]">
        <div class="flex flex-col gap-4 w-full" v-if="plan_is_loading">
            <div class="skeleton-block title"></div>
            <div class="skeleton-block subtitle"></div>
            <div class="skeleton-block btn"></div>
            <div class="skeleton-block cover"></div>
            <div class="flex gap-2">
                <div class="skeleton-block btn"></div>
                <div class="skeleton-block btn"></div>
            </div>
        </div>

        <div class="flex w-full" v-if="!plan_is_loading && !plan?.id">
            <alert_component>
                <template #title> {{ gt("Hey...") }} </template>
                <template #message>
                    {{
                        gt(
                            "It seems that something is not quite right, contact us.",
                        )
                    }}
                </template>
            </alert_component>
        </div>

        <div v-if="plan?.id" class="flex flex-col gap-4 w-full">
            <div class="flex flex-col gap-2">
                <title_component :size="'large'">
                    <template #title> {{ title }} </template>
                </title_component>

                <div class="flex w-full" v-if="progress > 0">
                    <div class="progress glassed">
                        <div
                            class="progress-bar"
                            :style="{ width: `${progress}%` }"
                        ></div>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <button_component
                        :classes="['btn', 'btn-light', 'w-fit']"
                        @click="get_plan_info()"
                    >
                        <i class="fi fi-rr-information"></i>
                        {{ gt("View plan information") }}
                    </button_component>

                    <button_component
                        :classes="['btn', 'btn-dark', 'w-fit']"
                        :disabled="routes_is_loading || !routes.length"
                        @click="continue_with()"
                    >
                        <i class="fi fi-rr-play"></i>
                        {{ gt("Continue") }}
                    </button_component>
                </div>
            </div>

            <div class="flex w-full" v-if="routes_is_loading">
                <div class="skeleton-block cover"></div>
            </div>

            <div
                class="flex w-full"
                v-if="!routes_is_loading && routes.length === 0"
            >
                <alert_component>
                    <template #title> {{ gt("Hey...") }} </template>
                    <template #message>
                        {{
                            gt(
                                "It seems that something is not quite right, contact us.",
                            )
                        }}
                    </template>
                </alert_component>
            </div>

            <div class="routes" v-if="!routes_is_loading && routes.length > 0">
                <div
                    v-for="route in routes"
                    :key="route"
                    :class="['route', route.active === true ? 'active' : '']"
                >
                    <div
                        v-if="route.next_to_unlock === true && route.locked"
                        class="flex flex-col text-sm text-slate-700"
                    >
                        {{ gt("Next to unlock ↓") }}
                    </div>

                    <!-- Alerts -->
                    <template v-if="route.next_to_unlock === true">
                        <need_appointment_component
                            v-if="
                                PLAN_LOCK_CODES.NEED_APPOINTMENT ===
                                    route.lock_code ||
                                PLAN_LOCK_CODES.NEED_FINISHED_APPOINTMENT ===
                                    route.lock_code
                            "
                            :code="route.lock_code"
                            :message="route.lock_message"
                            :route="route.route"
                            :plan="plan"
                        />
                    </template>

                    <!-- Route -->
                    <div
                        :class="[
                            'route-wrapper',
                            route.locked && !route.next_to_unlock
                                ? 'locked'
                                : '',
                            route.locked && route.next_to_unlock
                                ? 'completed'
                                : '',
                            route.progress === 100 ? 'completed' : '',
                        ]"
                        @click="go_to(route)"
                    >
                        <div
                            v-if="route.active === true"
                            class="image"
                            :style="{
                                backgroundImage: `url(${get_image_from_array(route.images)})`,
                            }"
                        ></div>

                        <div class="p-4 flex flex-col gap-4">
                            <div class="content">
                                <div class="info">
                                    <h3 class="title">
                                        {{ route.route.title }}
                                    </h3>

                                    <p class="description">
                                        {{ route.route.description }}
                                    </p>
                                </div>
                                <div class="r-button">
                                    <i
                                        class="fi fi-rr-play"
                                        v-if="!route.locked"
                                    ></i>
                                    <i
                                        class="fi fi-rr-lock"
                                        v-if="route.locked"
                                    ></i>
                                </div>
                            </div>

                            <div class="flex w-full">
                                <div class="progress">
                                    <div
                                        class="progress-bar"
                                        :style="{ width: `${route.progress}%` }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Alerts -->
                    <template v-if="route.next_to_unlock === true">
                        <blocked_route_component
                            v-if="
                                PLAN_LOCK_CODES.ROUTE_NOT_COMPLETED ===
                                    route.lock_code ||
                                PLAN_LOCK_CODES.WAITING_TIME === route.lock_code
                            "
                            :message="route.lock_message"
                        />
                    </template>

                    <div
                        v-if="
                            route.locked &&
                            route.setting.need_finished_appointment
                        "
                        class="legend"
                    >
                        <span class="icon">
                            <i class="fi fi-rr-calendar-exclamation"></i>
                        </span>

                        <span class="text-slate-700">
                            {{
                                gt(
                                    "You will need an appointment for this route.",
                                )
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <link_component
                    :classes="['btn', 'btn-condensed', 'btn-light', 'w-fit']"
                    :path="'/contact'"
                    :open_window="true"
                >
                    <i class="fi fi-rr-life-ring"></i>
                    {{ gt("Do you have any issues?") }}
                </link_component>

                <link_component
                    :classes="['btn', 'btn-condensed', 'btn-link', 'w-fit']"
                    :path="'/feedback'"
                    :open_window="true"
                >
                    <i class="fi fi-rr-message-quote"></i>
                    {{ gt("Suggest improvements") }}
                </link_component>
            </div>
        </div>
    </div>

    <modal_plan_finished_component
        v-if="all_routes_is_finished"
        :opened="all_routes_is_finished"
    />
</template>

<script setup>
// ENV Variables
import ENV from "@/../env.json";

// Components
import {
    defineAsyncComponent,
    onBeforeMount,
    onMounted,
    ref,
    computed,
} from "vue";
import { useRouter } from "vue-router";

const title_component = defineAsyncComponent(
    () => import("@/components/commons/title.vue"),
);

const button_component = defineAsyncComponent(
    () => import("@/components/commons/button.vue"),
);

const link_component = defineAsyncComponent(
    () => import("@/components/commons/link.vue"),
);

const alert_component = defineAsyncComponent(
    () => import("@/components/commons/alert.vue"),
);

const need_appointment_component = defineAsyncComponent(
    () => import("./alerts/need_appointment.vue"),
);

const blocked_route_component = defineAsyncComponent(
    () => import("./alerts/blocked_route.vue"),
);

const modal_plan_finished_component = defineAsyncComponent(
    () => import("./modals/plan_finished.vue"),
);

// Helpers
import url from "@/helpers/url";
import scroll_to_element from "@/helpers/scroll_to_element";
import is_native_framework from "@/helpers/is_native_framework.js";
import get_words from "@/helpers/get_words";
import get_shortened_text from "@/helpers/get_shortened_text";
import get_reading_time_from_string from "@/helpers/get_reading_time_from_string";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Services
import Plan from "@/methods/for_plans";

// Stores
import UserStore from "@/stores/user";
import AppStore from "@/stores/app";
import BottomNavigationStore from "@/stores/bottom_navigation";
import TopNavigationStore from "@/stores/top_navigation";

// Methods
const get_plan_slug = function () {
    const path = window.location.pathname.split("/");
    return path[path.length - 1];
};

// Variables
const PLAN_LOCK_CODES = ENV.enums.PlanLockCodes;

const User = UserStore();
const App = AppStore();
const BottomNavigation = BottomNavigationStore();
const TopNavigation = TopNavigationStore();

const $router = is_native_framework() ? useRouter() : null;
const slug = get_plan_slug();
const plan = ref(null);
const plan_is_loading = ref(true);
const routes = ref([]);
const routes_is_loading = ref(true);
const progress = ref(0);
const all_routes_is_finished = ref(false);

// Computed properties
const is_logged = computed(() => User.is_logged);

const title = computed(() =>
    get_shortened_text(get_words(plan.value.title, 12), 80),
);

// Methods
function get_plan_info() {
    App.notification({
        type: "modal",
        title: plan.value.title,
        message: plan.value.description,
        close_after: get_reading_time_from_string(plan.value.description),
    });
}

function continue_with() {
    if (routes.value.length === 0) {
        return;
    }

    const route = routes.value.find((x) => x.active === true);

    if (!route) {
        return;
    }

    go_to(route);
}

function go_to(x) {
    const { route, locked } = x;
    const route_path = `/route/${route.slug}?plan_id=${plan.value.id}`;

    if (locked) {
        return;
    }

    if ($router) {
        $router.push(
            url({
                path: route_path,
                with_origin: false,
                with_locale: false,
            }),
        );
        return;
    }

    window.location.href = url({
        path: route_path,
    });
}

function get_image_from_array(images) {
    if (!images || images.length === 0) {
        return "";
    }

    return url({ path: images[0].path, with_locale: false });
}

async function get_plan_by_slug() {
    plan_is_loading.value = true;

    const { records } = await Plan.get({
        filters: {
            specific: true,
            slug,
        },
    });

    plan.value = records.length > 0 ? records[0] : null;
    plan_is_loading.value = false;

    TopNavigation.update({
        loading: false,
        title: plan.value.title,
    });
}

async function get_my_plan_status() {
    routes_is_loading.value = true;

    const { status } = await Plan.my_plan_status({
        filters: {
            plan_id: plan.value.id,
        },
    });

    if (!status) {
        return;
    }

    progress.value = status.plan.progress;
    routes.value = status.routes || [];
    all_routes_is_finished.value = status.plan.progress >= 100;
    routes_is_loading.value = false;

    if (all_routes_is_finished.value) {
        window.dispatchEvent(new Event("plan_finished"));
    }

    if (routes.value.length > 1) {
        setTimeout(() => {
            scroll_to_element(".route.active");
        }, 1000);
    }
}

function redirect_if_is_not_logged() {
    if (is_logged) {
        return;
    }

    if (!$router) {
        window.location.href = "/login";
    } else {
        $router.push("/login");
    }
}

function set_title_and_navigation() {
    TopNavigation.update({
        loading: false,
        visible: true,
        title: gt("My Plan"),
        go_back: "/journey",
    });

    BottomNavigation.update({
        visible: true,
        active_menu: "continue",
    });
}

// Lifecycle
onBeforeMount(() => {
    redirect_if_is_not_logged();
    set_title_and_navigation();
});

onMounted(async () => {
    if (!is_logged) {
        return;
    }

    await get_plan_by_slug();
    await get_my_plan_status();
});
</script>

<style lang="sass" scoped>
@use "./styles/index.scss"
</style>
