// ENV Variables
import ENV from "@/../env.json";

// Stores
import AppStore from "@/stores/app";

/**
 * Get resources.
 */
export default async function (Service, args = {}) {
    // Reusable Instances
    const silent = args.silent || false;

    args.page = args.page || 1;
    args.per_page = args.per_page || 10;
    args.with_properties = args.with_properties || [];
    args.with_relations = args.with_relations || [];
    args.with_relations_counts = args.with_relations_counts || [];
    args.order_by = args.order_by || null;
    args = { ...args, ...args.filters };

    args.with_properties = args.with_properties.join("|");
    args.with_relations = args.with_relations.join("|");
    args.with_relations_counts = args.with_relations_counts.join("|");

    delete args.filters;

    const response = await Service(args);

    const { success, message } = response;

    if (!silent && message) {
        try {
            AppStore().notification({
                message,
                level: success
                    ? ENV.enums.AlertLevels.SUCCESS
                    : ENV.enums.AlertLevels.DANGER,
            });
        } catch (error) {}
    }

    return response;
}
