<template>
    <div class="flex flex-col gap-2">
        <i class="text-slate-700 text-sm">Disponible en los formatos:</i>
        <div class="flex flex-wrap justify-start gap-2">
            <button_component
                v-if="post.audios && post.audios.length > 0"
                :classes="['btn', 'btn-dark']"
                :loading="loading"
                @click="open(post)"
            >
                <i class="fi fi-rr-play"></i>
                Audio
            </button_component>

            <button_component
                v-if="post.videos && post.videos.length > 0"
                :classes="['btn', 'btn-dark']"
                :loading="loading"
                @click="open(post)"
            >
                <i class="fi fi-rr-play-alt"></i>
                Video
            </button_component>

            <button_component
                :classes="['btn', 'btn-light']"
                :loading="loading"
                @click="scroll_to_element('.post-content')"
            >
                <i class="fi fi-rr-book-alt"></i>
                Articulo
            </button_component>
        </div>
    </div>
</template>

<script setup>
// Components
import { defineAsyncComponent } from "vue";

const button_component = defineAsyncComponent(
    () => import("@/components/commons/button.vue"),
);

// Libs
import { toRefs, onMounted, ref } from "vue";

// Helpers
import scroll_to_element from "@/helpers/scroll_to_element";

// Stores
import PlayerStore from "@/stores/player";

// Methods
import Post from "@/methods/for_posts";

// Define props
const props = defineProps({
    post_id: {
        default: {},
    },
});

// Variables
const Player = PlayerStore();
const { post_id } = toRefs(props);
const post = ref({});
const loading = ref(true);

// Methods
const get_post_by_post_id = async () => {
    loading.value = true;

    const { records } = await Post.get({
        with_properties: ["images", "audios", "videos"],
        specific: true,
        filters: {
            search: post_id.value,
        },
    });

    post.value = records.length > 0 ? records[0] : {};
    loading.value = false;
};

const open = (post) => {
    Player.add_resource({
        type: "post",
        ...post,
    });
};

// Lifecycle
onMounted(async () => {
    await get_post_by_post_id();
});
</script>
