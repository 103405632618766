<template>
    <div class="card p-4">
        <button_component
            :classes="['btn', 'btn-danger', 'w-full']"
            :loading="loading"
            @click="delete_notifications()"
        >
            <i class="fi fi-rr-trash"></i>
            {{ gt("Stop receiving notifications") }}
        </button_component>
    </div>
</template>
<script setup>
// Components
import { defineAsyncComponent } from "vue";

const button_component = defineAsyncComponent(
    () => import("@/components/commons/button.vue"),
);

// Libs
import { toRefs, ref } from "vue";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Stores
import User from "@/methods/for_users";

// Props
const props = defineProps({
    user_id: {
        default: null,
    },

    on_response: {
        default: null,
    },
});

// Variable
const { user_id, on_response } = toRefs(props);
const loading = ref(false);

// Methods
const delete_notifications = async () => {
    loading.value = true;

    const { success, message } = await User.remove_notifications({
        id: user_id.value,
    });

    if (on_response.value) {
        on_response.value({ success, message });
    }

    loading.value = false;
};
</script>
