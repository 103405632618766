export default function get_words(value, limit = 100, end = "...") {
    if (!value) return "";

    const regex = new RegExp(`^(\\s*\\S+){1,${limit}}`);
    const match = value.match(regex);

    if (!match || value.length === match[0].length) {
        return value;
    }

    return match[0].trim() + end;
}
