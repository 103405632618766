<template>
    <div class="w-full flex flex-col gap-2">
        <template v-if="loading_route">
            <div class="flex flex-col gap-2">
                <div class="skeleton-block title"></div>
                <div class="skeleton-block subtitle"></div>
                <div class="skeleton-block route mt-2"></div>
                <div class="skeleton-block route"></div>
                <div class="skeleton-block route"></div>
            </div>
        </template>

        <template v-if="!loading_route && !route?.id">
            <alert_component>
                <template #message>
                    {{ gt("We did not find what you are looking for.") }}
                </template>
            </alert_component>
        </template>

        <template v-if="!loading_route && route?.id">
            <title_component v-if="show_title" size="large">
                <template #title>
                    {{ title }}
                </template>
            </title_component>

            <route_button_likes :route_id="route?.id" />

            <div class="flex flex-col gap-2">
                <button_component
                    :classes="['btn', 'btn-light', 'w-fit']"
                    @click="get_route_info()"
                >
                    <i class="fi fi-rr-information"></i>
                    {{ gt("View route information") }}
                </button_component>
            </div>

            <template v-if="!loading_route && route">
                <units_component :route_id="route.id" />
            </template>
        </template>
    </div>
</template>
<script setup>
// Components
import { computed, defineAsyncComponent, onBeforeMount } from "vue";
import { ref, toRefs, onMounted } from "vue";

const alert_component = defineAsyncComponent(
    () => import("@/components/commons/alert.vue"),
);

const route_button_likes = defineAsyncComponent(
    () => import("@/components/app/routes/buttons/likes.vue"),
);

const units_component = defineAsyncComponent(
    () => import("./components/units.vue"),
);

const title_component = defineAsyncComponent(
    () => import("@/components/commons/title.vue"),
);

const button_component = defineAsyncComponent(
    () => import("@/components/commons/button.vue"),
);

// Props
const props = defineProps({
    route_slug: {
        type: String,
        default: null,
    },

    show_title: {
        type: Boolean,
        default: false,
    },
});

// Helpers
import get_query_param from "@/helpers/get_query_param.js";
import get_shortened_text from "@/helpers/get_shortened_text.js";
import get_words from "@/helpers/get_words.js";
import get_reading_time_from_string from "@/helpers/get_reading_time_from_string.js";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Libs
import Route from "@/methods/for_routes";
import Plan from "@/methods/for_plans";

// Stores
import UserStore from "@/stores/user";
import AppStore from "@/stores/app";
import TopNavigationStore from "@/stores/top_navigation.js";
import BottomNavigationStore from "@/stores/bottom_navigation.js";

// Variables
const TopNavigation = TopNavigationStore();
const BottomNavigation = BottomNavigationStore();
const User = UserStore();
const App = AppStore();

const { route_slug } = toRefs(props);
const route = ref(null);
const plan = ref(null);
const plan_id = get_query_param("plan_id");
const loading_route = ref(true);
const { is_logged } = User;

// Computed variables
const title = computed(() => {
    return get_shortened_text(get_words(route.value?.title, 8), 60);
});

// Methods
function get_route_info() {
    App.notification({
        type: "modal",
        title: route.value.title,
        message: route.value.description,
        close_after: get_reading_time_from_string(route.value.description),
    });
}

const get_route_by_slug = async () => {
    loading_route.value = true;

    if (!route_slug.value) {
        return;
    }

    const { records } = await Route.get({
        with_properties: ["images"],
        specific: true,
        filters: {
            slug: route_slug.value,
        },
    });

    if (records.length === 0) {
        loading_route.value = false;
        return;
    }

    route.value = records[0];

    TopNavigation.update({
        loading: false,
        title: route.value?.title,
        go_back: `/journey`,
    });

    loading_route.value = false;
};

const get_plan_by_id = async () => {
    if (!is_logged) {
        return;
    }

    if (!plan_id) {
        return;
    }

    const { records } = await Plan.get({
        with_properties: [],
        specific: true,
        filters: {
            id: plan_id,
        },
    });

    plan.value = records[0];

    TopNavigation.update({
        loading: false,
        go_back: plan.value ? `/plan/${plan.value.slug}` : `/journey`,
    });
};

function set_title_and_navigation() {
    TopNavigation.update({
        loading: true,
        visible: true,
        title: gt("Route"),
    });

    BottomNavigation.update({
        visible: true,
        active_menu: "continue",
    });
}

// Lifecycle
onBeforeMount(() => {
    set_title_and_navigation();
});

onMounted(() => {
    get_route_by_slug();
    get_plan_by_id();
});
</script>
<style lang="sass" scoped>
@use "./styles/index.scss"
</style>
