// Service
import BaseService from "@/services/base";

export default class extends BaseService {
    constructor(args = {}) {
        super(args);
    }

    get = async (body = {}) => {
        return await this.execute({
            name: "symptom.get",

            method: "get",
            target: "/v1/symptoms",
            body,

            schema: {
                success: {
                    required: ["symptoms"],
                    properties: {
                        symptoms: { type: "array" },
                    },
                },
            },
        });
    };

    store = async (body = {}) => {
        return await this.execute({
            name: "symptom.store",

            method: "post",
            target: "/v1/symptom",
            body,

            schema: {
                success: {
                    required: ["symptom"],
                    properties: {
                        symptom: { type: "object" },
                    },
                },
            },
        });
    };

    delete = async (body = {}) => {
        return await this.execute({
            name: "symptom.delete",

            method: "delete",
            target: "/v1/symptom",
            body,
        });
    };
}
