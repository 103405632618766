import ENV from "../../env.json";

import { defineStore } from "pinia";
import { reactive } from "vue";

export default defineStore("app", () => {
    const notification_queue = reactive([]);
    const popup = reactive({
        active: false,
        level: ENV.enums.AlertLevels.DARK,
        type: "alert",
        close_after: null,
        message: "",
        progress: 0,
    });

    let is_processing = false;
    let stop_current_notification = false;

    function add_notification(notification) {
        const close_after = notification.close_after || 5;
        const active =
            notification.active === undefined ? true : notification.active;

        const is_duplicate = notification_queue.some(
            (existing_notification) =>
                existing_notification.message === notification.message,
        );

        const is_unauthenticated = notification.message === "Unauthenticated.";

        if (is_duplicate || is_unauthenticated) {
            return;
        }

        if (notification_queue.length < 10) {
            notification_queue.push({
                ...notification,
                active,
                close_after,
                level: !notification.level
                    ? ENV.enums.AlertLevels.LIGHT
                    : notification.level,
            });
        }

        if (!is_processing) {
            process_notifications();
        }
    }

    async function process_notifications() {
        if (is_processing || notification_queue.length === 0) return;

        is_processing = true;

        while (notification_queue.length > 0) {
            const current_notification = notification_queue.shift();

            Object.assign(popup, {
                ...popup,
                ...current_notification,
                active: true,
                progress: 0,
            });

            stop_current_notification = false;

            const start = performance.now();
            const duration = current_notification.close_after * 1000;

            const update_progress = (timestamp) => {
                if (stop_current_notification) {
                    popup.active = false;
                    popup.progress = 0;
                    return;
                }

                const elapsed = timestamp - start;
                popup.progress = Math.min((elapsed / duration) * 100, 100);

                if (elapsed < duration) {
                    requestAnimationFrame(update_progress);
                } else {
                    popup.progress = 100;
                    setTimeout(() => {
                        popup.active = false;
                        popup.progress = 0;
                    }, 500);
                }
            };

            await new Promise((resolve) => {
                requestAnimationFrame((timestamp) => {
                    update_progress(timestamp);
                    resolve();
                });
            });

            await new Promise((resolve) => {
                requestAnimationFrame((timestamp) => {
                    update_progress(timestamp);
                    setTimeout(resolve, duration + 500);
                });
            });

            if (stop_current_notification) {
                continue;
            }
        }

        is_processing = false;
    }

    function close_notification() {
        if (popup.active) {
            stop_current_notification = true;
            popup.active = false;
            popup.progress = 0;
        }

        if (!is_processing && notification_queue.length > 0) {
            process_notifications();
        }
    }

    return {
        popup,
        notification: add_notification,
        close_notification,
    };
});
