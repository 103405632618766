// Service
import BaseService from "@/services/base";

export default class extends BaseService {
    constructor(args = {}) {
        super(args);
    }

    get_session = async (body = {}) => {
        return await this.execute({
            name: "session.get_session",

            method: "get",
            target: "/v1/session",
            body,
        });
    };
}
