<template>
    <div class="mobile-navigation" v-if="is_visible && user_is_logged">
        <div class="content">
            <div
                :class="['item', { active: active_menu === 'home' }]"
                :style="`padding-bottom: max(var(--safe-area-inset-bottom, 0px), 1rem)`"
                @click="go_to('/performance')"
            >
                <div class="fi-content">
                    <i class="fi fi-rr-home-location-alt"></i>
                </div>

                <span>{{ gt("Home") }}</span>
            </div>

            <div
                :class="['item', { active: active_menu === 'continue' }]"
                :style="`padding-bottom: max(var(--safe-area-inset-bottom, 0px), 1rem)`"
                @click="go_to(`/journey`)"
            >
                <div class="fi-content">
                    <i class="fi fi-rr-journey"></i>
                </div>

                <span>{{ gt("Continue") }}</span>
            </div>

            <div
                :class="['item', { active: active_menu === 'ranking' }]"
                :style="`padding-bottom: max(var(--safe-area-inset-bottom, 0px), 1rem)`"
                @click="go_to(`/ranking`)"
            >
                <div class="fi-content">
                    <i class="fi fi-rr-ranking-star"></i>
                </div>

                <span>{{ gt("Ranking") }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
// Libs
import { computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";

// Helpers
import url from "@/helpers/url";
import is_native_framework from "@/helpers/is_native_framework.js";

// Stores
import UserStore from "@/stores/user";
import BottomNavigationStore from "@/stores/bottom_navigation";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Variables
const User = UserStore();
const Navigation = BottomNavigationStore();

const $router = is_native_framework() ? useRouter() : null;

// Computed properties
const is_visible = computed(() => Navigation.visible);
const active_menu = computed(() => Navigation.active_menu);
const user_is_logged = computed(() => User.is_logged);
const bottom_navigation_was_update_at = computed(() => Navigation.updated_at);

// Watchers
watch(bottom_navigation_was_update_at, () => {
    setTimeout(() => {
        set_css_bottom_navigation_height_variable();
    }, 150);
});

// Methods
function set_css_bottom_navigation_height_variable() {
    let bottom_safe_space = 14;
    const wrapper = document.querySelector(".wrapper-for-app-pages");
    const navigation = document.querySelector(".mobile-navigation");

    if (navigation) {
        bottom_safe_space += navigation.offsetHeight;
    }

    if (wrapper) {
        wrapper.style.paddingBottom = `${bottom_safe_space}px`;
    }

    localStorage.setItem("bottom_safe_space", `${bottom_safe_space}px`);
}

function go_to(path) {
    if ($router) {
        $router.push(path);
        return;
    }

    window.location.href = url({ path });
}

// Lifecycle
onMounted(() => {
    setTimeout(() => {
        set_css_bottom_navigation_height_variable();
    }, 150);
});
</script>

<style lang="scss" scoped>
@use "./styles/navigation.scss";
</style>
