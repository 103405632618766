// ENV Variables
import ENV from "@/../env.json";

// Stores
import AppStore from "@/stores/app";

/**
 * Stores a new resource.
 */
export default async function (
    Service,
    { $form = null, append = {}, silent = false },
) {
    // Reusable Instances
    const body = {};

    if (!$form) {
        $form = {
            is_loading: false,
            inputs: {},
            error: null,
        };
    }

    $form.is_loading = true;

    Object.keys($form.inputs).forEach((key) => {
        body[key] = $form.inputs[key].value;
    });

    if (append) {
        Object.keys(append).forEach((key) => {
            if (append[key] !== null) {
                body[key] = append[key];
            }
        });
    }

    const response = await Service(body);
    const { success = false, message = null, errors = {} } = response;

    Object.keys(errors).forEach((key) => {
        if ($form.inputs[key]) {
            $form.inputs[key].errors = errors[key];
        }
    });

    $form.error = message || null;

    if (!silent && message) {
        try {
            AppStore().notification({
                title: "Hey",
                message,
                level: success
                    ? ENV.enums.AlertLevels.SUCCESS
                    : ENV.enums.AlertLevels.DANGER,
            });
        } catch (error) {}
    }

    $form.is_loading = false;

    return { $form, ...response };
}
