<template>
    <span :class="['flex', 'items-center', 'gap-1', ...classes]">
        <i
            v-if="with_copy"
            class="cursor-pointer fi fi-rr-copy-alt"
            @click="on_click()"
        ></i>

        <span>{{ is_expanded ? content : shortened_content }}</span>

        <span
            v-if="with_expand && !is_expanded && content.length > max_length"
            class="cursor-pointer btn btn-light btn-condensed"
            @click="expand"
        >
            <i class="fi fi-rr-arrow-down"></i>
            {{ gt("Read more") }}
        </span>
    </span>
</template>

<script setup>
// ENV Variables
import ENV from "@/../env.json";

// Libs
import { computed, ref, toRefs } from "vue";

// Helpers
import copy_to_clipboard from "@/helpers/copy_to_clipboard";
import get_shortened_text from "@/helpers/get_shortened_text";

// Translation needs
import { useI18n } from "vue-i18n";
import get_translation from "@/helpers/get_translation";
const { t } = useI18n();
const gt = (text, args) => get_translation(t, text, args);

// Stores
const ALERT_LEVELS = ENV.enums.AlertLevels;

import AppStore from "@/stores/app";

const props = defineProps({
    content: {
        default: "",
    },

    max_length: {
        default: 10,
    },

    classes: {
        default: [],
    },

    with_copy: {
        default: true,
    },

    with_expand: {
        default: false,
    },
});

const { content, max_length, with_copy, with_expand, classes } = toRefs(props);

const is_expanded = ref(false);

const shortened_content = computed(() => {
    return get_shortened_text(content.value, max_length.value);
});

// Methods
const on_click = async function () {
    const success = await copy_to_clipboard(content.value);

    if (success) {
        AppStore().notification({
            title: "Hey!",
            message: gt("Copied to clipboard!"),
            close_after: 3,
            level: ALERT_LEVELS.DARK,
        });
    }
};

const expand = function () {
    is_expanded.value = true;
};
</script>
