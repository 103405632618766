// ENV Variables
import ENV from "@/../env.json";

// Helpers
import get_locale from "@/helpers/get_locale";

export default function ({
    origin = "memeonlyme.com",
    path = "/",
    params = {},
    locale = null,
    with_locale = true,
    with_origin = true,
}) {
    const SOURCES = ENV.sources;
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const url_locale = locale || user?.language || get_locale();

    if (url_locale !== "es" && with_locale) {
        path = `/${url_locale}` + path;
    }

    let url = /^(https?:\/\/)/i.test(path)
        ? path
        : new URL(path, SOURCES[origin]).toString();

    const query_params = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value != null),
    );
    const query = new URLSearchParams(query_params).toString();
    const query_connector = url.includes("?") ? "&" : "?";
    const response = query ? url + query_connector + query : url;

    if (origin === "app" || !with_origin) {
        return response.replace(SOURCES[origin], "");
    }

    return response;
}
