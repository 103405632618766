// Wrappers
import GetWrapper from "./helpers/wrappers/get";
import StoreWrapper from "./helpers/wrappers/store";

// Services
import RewardService from "@/services/reward.js";
import PlanService from "@/services/plan.js";

export default {
    async get(args = {}) {
        const { success, rewards, user_progress } = await GetWrapper(
            new RewardService().get,
            args,
        );

        return {
            success,
            records: rewards || [],
            user_progress,
        };
    },

    async get_unclaimed(args = {}) {
        const { success, rewards } = await GetWrapper(
            new RewardService().get_unclaimed,
            args,
        );

        return {
            success,
            records: rewards || [],
        };
    },

    async claim({ $form, append = {} }) {
        return StoreWrapper(new RewardService().claim, {
            $form,
            append,
        });
    },
};
