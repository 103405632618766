// Styles
import "animate.css";

// Libs
import { createApp } from "vue";
import { register as registerSwiper } from "swiper/element/bundle";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import * as Sentry from "@sentry/vue";

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import ENV from "@/../env.json";

//
registerSwiper();

// Setup Echo
window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: "pusher",
    key: ENV.reverb.key,
    wsHost: ENV.reverb.wsHost,
    wsPort: ENV.reverb.wsPort,
    wssPort: ENV.reverb.wssPort || ENV.reverb.wsPort,
    forceTLS: ENV.reverb.forceTLS || false,
    enabledTransports: ["ws", "wss"],
    cluster: "mt1",
});

// Helpers
import setup_dynamic_components from "@/helpers/setup_dynamic_components";
import get_and_store_utm_variables from "@/helpers/get_and_store_utm_variables";
import get_and_store_user_session from "@/helpers/get_and_store_user_session";
import get_locale from "@/helpers/get_locale";

get_and_store_utm_variables();
get_and_store_user_session();

// Import translations
import en_translations from "@/locales/en.json";
import es_translations from "@/locales/es.json";

// App components
import post_button_likes from "@/components/app/posts/buttons/likes.vue";
import route_button_likes from "@/components/app/routes/buttons/likes.vue";
import post_button_play from "@/components/app/posts/buttons/play.vue";
import post_progress from "@/components/app/post/components/progress.vue";
import post_chat from "@/components/app/post/components/chat.vue";
import post_floating_post_banner from "@/components/dynamics/floating_post_banner/index.vue";

// Common components
import emoji_component from "@/components/commons/emoji.vue";
import shortener_component from "@/components/commons/shortener.vue";
import post_banner_for_call_to_actions from "@/components/dynamics/banner_for_call_to_actions/index.vue";

// Template components
import header_component from "@/components/template/web/header.vue";
import footer_component from "@/components/template/web/footer.vue";
import navigation_component from "@/components/template/web/navigation.vue";

// Vue Pages
import appointment_page from "@/components/wrappers/appointment/index.vue";
import appointments_page from "@/components/wrappers/appointments/index.vue";
import journey_page from "@/components/wrappers/journey/index.vue";
import login_page from "@/components/wrappers/login/index.vue";
import profile_page from "@/components/wrappers/profile/index.vue";
import subscription_page from "@/components/wrappers/subscribe/modules/manage.vue";
import rewards_page from "@/components/wrappers/rewards/index.vue";
import plan_page from "@/components/wrappers/plan/index.vue";
import register_page from "@/components/wrappers/register/index.vue";
import route_page from "@/components/wrappers/route/index.vue";
import routes_page from "@/components/wrappers/routes/index.vue";
import subscribe_page from "@/components/wrappers/subscribe/index.vue";
import index_page from "@/components/wrappers/index/index.vue";
import feedback_page from "@/components/wrappers/feedback/index.vue";
import contact_page from "@/components/wrappers/contact/index.vue";
import blog_page from "@/components/wrappers/blog/index.vue";
import performance_page from "@/components/wrappers/performance/index.vue";
import ranking_page from "@/components/wrappers/ranking/index.vue";
import recover_password_page from "@/components/wrappers/recover/index.vue";
import disable_notifications_page from "@/components/wrappers/disable_notifications/index.vue";

// Create i18n instance
const i18n = createI18n({
    legacy: false,
    locale: get_locale(),
    fallbackLocale: "en",
    messages: {
        en: en_translations,
        es: es_translations,
    },
});

const app = createApp({});
const pinia = createPinia();

app.use(pinia);
app.use(i18n);

app.component("shortener_component", shortener_component);
app.component("emoji_component", emoji_component);

// Template
app.component("header_component", header_component);
app.component("footer_component", footer_component);
app.component("navigation_component", navigation_component);

// Post components
app.component("post_button_likes", post_button_likes);
app.component("route_button_likes", route_button_likes);
app.component("post_button_play", post_button_play);
app.component("post_progress", post_progress);
app.component("post_chat", post_chat);
app.component("post_floating_post_banner", post_floating_post_banner);

// Vue Pages
app.component("index_page", index_page);
app.component("appointment_page", appointment_page);
app.component("appointments_page", appointments_page);
app.component("journey_page", journey_page);
app.component("login_page", login_page);
app.component("profile_page", profile_page);
app.component("subscription_page", subscription_page);
app.component("rewards_page", rewards_page);
app.component("plan_page", plan_page);
app.component("register_page", register_page);
app.component("route_page", route_page);
app.component("routes_page", routes_page);
app.component("subscribe_page", subscribe_page);
app.component("blog_page", blog_page);
app.component("recover_password_page", recover_password_page);
app.component("disable_notifications_page", disable_notifications_page);
app.component("feedback_page", feedback_page);
app.component("contact_page", contact_page);
app.component("performance_page", performance_page);
app.component("ranking_page", ranking_page);

// Dynamic components
document.addEventListener("DOMContentLoaded", () => {
    setup_dynamic_components(
        "post-content",
        "{post-cta}",
        post_banner_for_call_to_actions,
    );
});

// Stores
// Restore user session
import UserStore from "@/stores/user";
UserStore().restore();

// Sentry
if (window.location.hostname.endsWith("memeonlyme.com")) {
    Sentry.init({
        app,
        dsn: "https://06db6b6f0f45ee9a7968f77f57428576@o4506517952135168.ingest.us.sentry.io/4508896481050624",
        integrations: [],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["https://memeonlyme.com"],
        ignoreErrors: [
            /Failed to fetch dynamically imported module:/i,
            /NotAllowedError: Write permission denied.'/i,
            /The user canceled the sign-in flow./i,
        ],
    });
}

app.mount("#app");
