// Libs
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("bottom_navigation", () => {
    const visible = ref(false);
    const disabled = ref(false);
    const active_menu = ref("home");
    const updated_at = ref(new Date());

    const update = function (args = {}) {
        Object.keys(args).forEach((key) => {
            this[key] = args[key];
        });

        this.updated_at = new Date();
    };

    return {
        visible,
        disabled,
        active_menu,
        updated_at,

        update,
    };
});
